//import * as THREE from 'three'

export default scene => {
//	const light = new THREE.PointLight( '#ffde63', 1, 50000); // soft white light
//	scene.add(light);

	function update(time) {}

	return {
		update
	}
}